.item-container:hover {
    background-color: #e6e6e6;
    cursor: pointer;   
}
.item-container{
    border-radius: 5px;
    padding: 10px 20px;
}
.item-summary {
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.title-container {
    display: flex;
    align-items: center;
}
.title-icon {
    display: inline;
}
.title-text {
    display: inline;
}
.title-empty {
    display: inline;
    width: 1rem;
}
.expand {
    height: 100%;
}